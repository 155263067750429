import {authUrl} from '../config/config'

const sendAuthRequest = async (email, passwd) => {
    try {
        const url = authUrl
        const body = {
            username: email,
            password: passwd,
        }
		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
			body: JSON.stringify(body)
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	if (responseJson.hasOwnProperty('error')) {
	    		throw responseJson.error
	    	}
            // console.log(responseJson)
            return responseJson
        }).catch(error => {
        	throw error
        })
        // console.log('resp')
        // console.log(resp)
        
    	return resp
    } catch (err) {
    	console.error('ERROR AUTH:'+err)
        
        throw err
    }
};

export default sendAuthRequest