import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const RenewProxyConfirmDialog = (props) => {

  const handleClose = () => {
    props.setOpenConfirmRenew(false)
  }

  const calculateRenewPrice = (lease) => {
    const start = new Date(lease.start)
    const until = new Date(lease.until)

    const hours = (until.getTime() - start.getTime()) / 1000.0 / 60.0 / 60.0

    return Math.floor((lease.purchasePriceC / hours) * 24.0)
  }

  const handleRenew = () => {
    try {
        props.renewProxy()
        props.setOpenConfirmRenew(false)
    } catch (e) {
        throw e
    } 
  }

  return (
      <Dialog
        open={props.openConfirmRenew}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" , borderColor:'#3b82f680', border: '1px solid', maxWidth: '320px'} }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Renew proxy confirm"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Proxy will be renewed for next 24h, <span style={{fontWeight: 800}}>${(props.selectedProxy?.lastPaymentC/100).toFixed(2)}</span> will be deducted from your balance.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRenew} autoFocus variant='outlined'>
            Renew
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default RenewProxyConfirmDialog