import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography, TextField } from '@mui/material'

const RefundProxyConfirmDialog = (props) => {
    const [refundReson, setRefundReason] = useState("")
    const [buttonDisable, setButtonDisbled] = useState(true)

    const handleClose = () => {
        props.setOpenConfirmRefund(false)
    }

    const handleRefund = () => {
        try {
            props.refundProxy(refundReson)
        } catch (e) {
            throw e
        } 
    }
    
    const handleReasonChange = (e) => {
        
        if (e.target.value == "" || e.target.value.length === 0){
            
			setButtonDisbled(true)
			setRefundReason(e.target.value)
			return
		}

        setButtonDisbled(false)
        setRefundReason(e.target.value)
    }
  
  return (
      <Dialog
        open={props.openConfirmRefund}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "10px" , borderColor:'#3b82f680', border: '1px solid', maxWidth: '340px'} }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Refund proxy confirm"}
        </DialogTitle>
        <DialogContent>
            <Typography sx={{color:'#6E7680', fontSize: 14, paddingBottom:'20px'}}>By clicking the refund button, a refund ticket for proxy : <span style={{fontWeight: 800}}>{props.selectedProxy?.id}</span> will be created.</Typography>
            <Typography sx={{color:'#6E7680', fontSize: 14, paddingBottom:'20px'}}>Please type correct refund reason.</Typography>
            <TextField
                required
                id="outlined-required"
                label="Refund reason"
                inputProps={{sx:{width: '260px'},  maxLength: 50}}
                onChange={handleReasonChange} 
                />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleRefund} 
                    autoFocus 
                    variant='outlined'
                    disabled={buttonDisable}
                    >
                Refund
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  )
}

export default RefundProxyConfirmDialog