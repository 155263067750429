import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useAuth } from "../api/authStore"
import moment from 'moment'
import humanFormat from 'human-format'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
    mainDiv:{
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
    },
    divRow: {
        display: 'flex',
        alignItems:'center',
        textAlign:'center',
        justifyContent: 'center',
     },
     columnDiv: {
        flex: '50%',
     }
}))

const ProxyInfo = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()
    const [proxyId, setProxyId] = useState(null)
    const [error, setError] = useState(null)
    const [open, setOpen] = React.useState(false)

    const [proxyInfo, setProxyInfo] = useState([])

    const handleChangeProxyId = (id) => {
        setProxyId(id)
    }

    const handleGetInfo = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).getLeaseInfo(proxyId)
            // console.log(response)

            setProxyInfo(response)
        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e))
        }
    }

    const handleRefund = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).refundLeaseAdmin(proxyId)
            
            console.log(response)
        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e))
        }
    }

    return (
        <>
        <Box
            component="form"
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
            >
            <TextField  id="outlined-basic" 
                        label="proxy-id" 
                        variant="outlined" 
                        inputProps={{sx:{width: '140px'}}}
                        onChange={(event)=>{handleChangeProxyId(event.target.value)}}
                        value={proxyId}
                        />
            <Button variant="contained" sx={{height: '55px'}} onClick={handleGetInfo}>get info</Button>
            {error !== null &&
                <div style={{color:'red'}}>{error}</div>
            }
        </Box>
             <TableContainer component={Paper} sx={{maxWidth:850}}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Proxy ID</TableCell>
                            <TableCell align="left">{proxyInfo?.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Refunded</TableCell>
                            <TableCell align="left">{proxyInfo?.refundTime>0?moment(proxyInfo?.until).format('MMM-D H:m, YYYY'):'NO'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Start Date</TableCell>
                            <TableCell align="left">{moment(proxyInfo?.start).format('MMM-D H:m, YYYY')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={proxyInfo?.ended?{color:'red'}:{color:'green'}}>Expire Date</TableCell>
                            <TableCell align="left" sx={proxyInfo?.ended?{color:'red'}:{color:'green'}}>{moment(proxyInfo?.until).format('MMM-D H:m, YYYY')}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Traffic IN</TableCell>
                            <TableCell align="left">{proxyInfo?.stats?.inBytes>0?humanFormat.bytes(proxyInfo?.stats?.inBytes, {decimals: 0}):0}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Traffic OUT</TableCell>
                            <TableCell align="left">{proxyInfo?.stats?.outBytes>0?humanFormat.bytes(proxyInfo?.stats?.outBytes, {decimals: 0}):0}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Fails</TableCell>
                            <TableCell align="left">{proxyInfo?.stats?.failPct}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Authorisation by IP</TableCell>
                            <TableCell align="left">{JSON.stringify(proxyInfo?.authPort)}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Authorisation by Username</TableCell>
                            <TableCell align="left">{JSON.stringify(proxyInfo?.socks)}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>Price</TableCell>
                            <TableCell align="left">${proxyInfo?.purchasePriceC/100}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell align='left'>User ID</TableCell>
                            <TableCell align="left">{proxyInfo?.user}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' colSpan={2}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}Full Info
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='left' colSpan={2} sx={{maxWidth:'450px'}}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box component="pre">
                                        {JSON.stringify(proxyInfo, null, 2)}
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{width: 200}} align="right" colSpan={2}>
                                <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleRefund}
                                        >
                                            Refund
                                    </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ProxyInfo