import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import sendAuthRequest from './api/login'
import { useAuth } from "./api/authStore"

const useStyles = makeStyles((theme) => ({
    box: {
        width: '512px',
        height: '608px',
        border: '1px',
        display: 'flex',
        alignItems: 'center',
        top: '240px',
        left: '740px',
        border: '1px solid grey',
        gap: '48px',
        paddingLeft: '96px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centered: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#8000FF',
      },
      boxInline: {
        width : '100%',
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '96px',
        paddingRight: '96px',
      },
      signIn: {
        width: '320px',
        height: '40px',
      },
      emailDiv:{
        paddingBottom: '30px',
      },
      emailInput: {
        width: '320px',
        height: '56px',
      },
      passwdInput: {
        width: '320px',
        height: '56px',
      },
      button: {
        width: '320px',
        height: '56px',
      },
      link: {
        color: '#1A53FF',
      },
      divSignInLink : {
        color: '#6E7680',
      },
      errorDiv : {
        color: '#E0004B',
        paddingBottom: '10px',
        fontSize: 12
      }
}))

const Login = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [showPassword, setShowPassword] = React.useState(false)
    const [isError, showError] = React.useState(false)
    const [errorText, setErrorText] = useState("")

    const [email, setEmail] =  useState(null)
    const [passwd, setPassword] =  useState("")

    const handleChangeLogin = (e) => {
      setEmail(e.target.value)
    }
    
    const handleChangePasswd = (e) => {
      setPassword(e.target.value)
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleSingInButton = async() => {
      try {
          const result = await sendAuthRequest(email, passwd)
          setToken(result)
          window.location.href = '/proxies'
          // console.log(result)
      } catch (e) {
          if (e === 'userNotFound') {
              setErrorText("User already exist.")
          } else {
              setErrorText("Erorr occurs, try again later")
          }
          showError(true)
      }
  }

    return (
        <div className={classes.centered}>
            <Box my={4} p={2} className={classes.box}>
                <div id="boxInline" className={classes.boxInline}>
                    <div className={classes.signIn}>Sign In</div>
                    <div id="elements">
                       <div className={classes.emailDiv}>
                            <TextField
                                id="outlined-size-small"
                                placeholder="yours@example.com"
                                className={classes.emailInput}
                                InputProps={{
                                    style: {
                                      borderRadius: "8px",
                                    }
                                  }}
                                onChange={handleChangeLogin}
                            />
                        </div>
                        <div className={classes.emailDiv}>
                            <TextField
                                id="outlined-size-small"
                                placeholder="your password"
                                className={classes.passwdInput}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>,
                            }}
                                  onChange={handleChangePasswd}
                            />
                        </div>
                        <div className={classes.errorDiv}>
                            {isError ? (
                                <span>{errorText}</span>
                                ): (
                                <span>&nbsp;</span>
                                )
                            }
                        </div>
                        {/* <div className={classes.emailDiv}><span className={classes.link}><a href="/passwdrecovery">Forgot password?</a></span></div> */}
                        <div className={classes.emailDiv}>
                                <Button variant="contained" className={classes.button} sx={{ borderRadius: '8px' }} onClick={()=>{handleSingInButton()}}>Sign In</Button>
                        </div>
                        <div className={classes.divSignInLink}>
                            <span>Don’t have an account?</span>
                        </div>
                        <div className={classes.divSignInLink}>
                            <span><a href="/signup">Sign Up!</a></span>
                        </div>
                        <div className={classes.divSignInLink} style={{paddingTop: '30px'}}>
                            <span>Support email: <a href="mailto:xsocks@yahoo.com">xsocks@yahoo.com</a></span>
                        </div>
                        <div className={classes.divSignInLink}>
                            <span>Support telegram: <a href="tg://resolve?domain=xsocks_support">@xsocks_support</a></span>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
       
    )
}

export default Login