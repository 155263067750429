import React from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useState } from "react"

export const useAuth = () => {
    const [user, setUser] = useLocalStorage("token", null)

    const getAccessTokenSilently =  () => {
        // console.log('token')
        // console.log(user.access_token)
        try {
            return user?.access_token
        } catch (error) {
            return null
        }
    }

    const setToken = (token) => {
        setUser(token)
    }

    const logout =  (url) => {
        setUser(null)
        window.location.href = url
    }

    return { user, getAccessTokenSilently, setToken, logout }
}