import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ReactCountryFlag from "react-country-flag"
import { useAuth } from "./api/authStore"
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'


const useStyles = makeStyles((theme) => ({
    main: {
        width: '60%',
        height: '100vh',
    },
    header: {
        // height: '72px',
        margin: '32px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        marginLeft: '32px',
        marginRight: '32px',
        height: '400px',
        border: '1px solid',
        borderRadius: '16px',
        justifyContent: 'center',
        textAlign:'center',
        paddingTop: '200px'
    },
    linkStyle: {
        color: '#8000FF',
        textDecoration: 'none',
    },
    buyButton: {
        width: '180px',
        fontWeight: '700px'
    },
    dotStyle: {
        color: '#6E7680'
    },
    ipLocationData: {
        height: '24px',
        fontWeight: '400',
        color: '#1A53FF',
        fontSize: '10px',
     },
}))

const ConfirmDialog = (props) => {
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const classes = useStyles()

    const selectedProxy = props.selectedProxy
    // console.log(selectedProxy)
    const price = selectedProxy?.priceExcC > 0 ? selectedProxy?.priceExcC : selectedProxy?.priceShrC

    const [openBuyError, setBuyError] = useState(false)
    const [openBuySuccess, setBuySuccess] = useState(false)
    const [alreadyPurchased, setAlreadyPutchased] = useState(false)

    const handleClose = () => {
        props.setConfirmOpen(false)
    }

    const handleBuyPrivate = async() => {
        try {
            const proxy = {
                agentID: selectedProxy.id,
                exc: true,
                expPriceC: selectedProxy?.priceExcC,
                probe: false,
            }
            
            const response = await props.apiClient.setToken(getAccessTokenSilently()).buyProxy(proxy)

            props.setConfirmOpen(false)
            setBuySuccess(true)
            props.setAfterBuy(true)
        } catch (e) {
            // console.error('ERROR2')
            // console.log(e.response.data.error)
            if(e.response.data.error === 'paramInvalid'){

            } else if (e.response.data.error === 'agentAlreadyLeased'){
                setAlreadyPutchased(true)
            } else {
                setBuyError(true)
            }

            props.setConfirmOpen(false)
            
        }
    }

    const handleBuyShared = async() => {
        try {
            const proxy = {
                agentID: selectedProxy.id,
                exc: false,
                expPriceC: selectedProxy.priceShrC,
                probe: false,
            }

            const response = await props.apiClient.setToken(getAccessTokenSilently()).buyProxy(proxy)

            props.setConfirmOpen(false)
            setBuySuccess(true)
            props.setAfterBuy(true)
        } catch (e) {
            if(e.response.data.error === 'paramInvalid'){

            } else if (e.response.data.error === 'agentAlreadyLeased'){
                setAlreadyPutchased(true)
            } else {
                setBuyError(true)
            }

            props.setConfirmOpen(false)
        }
    }
    
    const handleBuyErrorClose = () => {
        setBuyError(false)
    }

    const handleBuySuccessClose = () => {
        setBuySuccess(false)
    }

    const handleAlreadyPurchasedClose = () => {
        setAlreadyPutchased(false)
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Proxy Purchase Confirmation"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>The proxy will be rented by you for 24 hours.</Typography>
                    <Typography>You can refund proxy if it doesn't work in first 2 hours.</Typography>
                    <Typography>Proxy price is ${(price/100).toFixed(2)}, and it will be deducted from your account balance.</Typography>
                    <Typography>Confirming purchase you agree with our <Link href="" className={classes.linkStyle} underline="none">terms and conditions</Link></Typography>
                    <Typography>Proxy info : </Typography>
                    <Typography className={classes.ipLocationData}><ReactCountryFlag countryCode={selectedProxy?.loc?.cc} svg style={{width: '16px', height: '12px', paddingRight: '15px'}}/><span>{selectedProxy?.loc?.reg}</span><span className={classes.dotStyle}> • </span><span>{selectedProxy?.loc?.city}</span><span className={classes.dotStyle}> • </span><span className={classes.dotStyle}>{selectedProxy?.loc?.zip}</span><span className={classes.dotStyle}> • </span><span>{selectedProxy?.loc?.isp.replace(/(.{15})..+/, "$1…")}</span></Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {selectedProxy?.priceExcC !== undefined ? (
                    <>
                    <Button onClick={handleBuyShared} 
                            autoFocus 
                            className={classes.buyButton} 
                                    variant="contained"
                            >
                        Buy Shared ${(selectedProxy?.priceShrC/100).toFixed(2)} 
                    </Button>
                    <Button onClick={handleBuyPrivate} 
                            autoFocus 
                            className={classes.buyButton} 
                            variant="contained"
                    >
                        Buy Private ${(selectedProxy?.priceExcC/100).toFixed(2)} 
                    </Button>
                    </>
                ):(
                        <Button onClick={handleBuyShared} 
                            autoFocus 
                            className={classes.buyButton} 
                            variant="contained"
                    >
                        Buy Shared ${(selectedProxy?.priceShrC/100).toFixed(2)} 
                    </Button>
                )}
                </DialogActions>
            </Dialog>
            <Snackbar
              open={openBuyError}
              autoHideDuration={6000}
              onClose={handleBuyErrorClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Error while purchasing proxy.</Alert>
            </Snackbar>
            <Snackbar
              open={alreadyPurchased}
              autoHideDuration={6000}
              onClose={handleAlreadyPurchasedClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Current proxy was already purchased.</Alert>
            </Snackbar>
            <Snackbar
              open={openBuySuccess}
              autoHideDuration={6000}
              onClose={handleBuySuccessClose}
              severity="success"
              variant="filled"
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Proxy was successfully purchased!</Alert>
            </Snackbar>
        </>
    )
}

export default ConfirmDialog