import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import EmptyTable from './EmptyTable'
import { useAuth } from "./api/authStore"
import moment from 'moment'
import Chip from '@mui/material/Chip'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'


const useStyles = makeStyles((theme) => ({
    main: {
        width: '77%',
        height: '100vh',
    },
    header: {
        // height: '72px',
        margin: '32px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        marginLeft: '18px',
        // marginRight: '32px',
    },
    tableDiv: {
        marginTop: '16px',
    },
    tableDataCell: {
        height: '20px',
        paddingTop: '15px',
    },
    dateDiv: {
      columnCount: 2,
      width: '420px'
    },
    emptyContent: {
      height: '72px',
      marginLeft: '18px',
      marginRight: '32px',
      height: '400px',
      border: '1px solid',
      borderRadius: '16px',
      justifyContent: 'center',
      textAlign:'center',
      paddingTop: '200px',
      marginTop: '32px'
  },
}))

function TablePaginationActions(props) {
    const theme = useTheme()
    
    const { count, page, rowsPerPage, onPageChange } = props
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0)
    }
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1)
    }
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1)
    }
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
         <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
            <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        </IconButton>
      </Box>
    )
  }

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }

const PaymentsTable = (props) => {
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()
    
    const classes = useStyles()

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [paymentsList, setPaymentsList] = React.useState([])
    const [startDate, setStartDate] = React.useState(dayjs())
    const [endDate, setEndDate] = React.useState(dayjs().endOf('month'))


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentsList?.txRecords?.length) : 0

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // console.log('START:'+startDate)

    useEffect(()=>{
      const callAsync = async() => {
          try {
            const dateStartFormated = startDate.format('YYYY-MM-DD 00:00:00')
            const dateEndFormated = endDate.format('YYYY-MM-DD 23:59:59')
            const paymentsPesponse = await props.apiClient.setToken(getAccessTokenSilently()).getUserPayments(dateStartFormated, dateEndFormated, page, rowsPerPage)
            // console.log(historyPesponse.items)
            setPaymentsList(paymentsPesponse)
          } catch (e) {
              // console.error(e)
          }
          
      }
     
      callAsync()   
    },[startDate, endDate, page, rowsPerPage])

    const handleSetStartDate = (date) => {
      setStartDate(date)
    }
  
    const handleSetEndDate = (date) => {
      setEndDate(date)
    }

    return (
        <div className={classes.main}>
            <div className={classes.header}>Transactions</div>
            <div className={classes.dateDiv}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{marginLeft: '18px', width: '200px'}}>
                  <DatePicker label="From" value={startDate} onChange={(newValue) => handleSetStartDate(newValue)}/>
                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{marginLeft: '18px', width: '200px'}}>
                  <DatePicker label="To" value={endDate} onChange={(newValue) => handleSetEndDate(newValue)}/>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          {paymentsList?.txRecords?.length>0 ? (
            <div className={classes.content}>
                <div className={classes.tableDiv}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                        <Table stickyHeader sx={{borderColor: '#B6BABF'}}>
                            <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Date</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Type</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Description</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Amount</TableCell>
                                <TableCell align="left" sx={{ borderBottom: "none" }}>Balance</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {paymentsList?.txRecords?.map((row) => (
                                <TableRow>
                                    <TableCell align="left" sx={{ borderBottom: "none"}}>{moment(row?.time).format('L LT')}</TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                                      {row?.type === 'lease'? (
                                          <Chip label="lease" color="success" variant="outlined" sx={{width: '100px'}}/>
                                        ):row?.type === 'refund'?(
                                          <Chip label="refund" color="warning" variant="outlined" sx={{width: '100px'}}/>
                                        ):row?.type === 'deposit'?(
                                          <Chip label="deposit" color="primary" variant="outlined" sx={{width: '100px'}}/>
                                        ):(
                                          <></>
                                        )
                                      }
                                    </TableCell>
                                    <TableCell align="left" sx={{ borderBottom: "none" }}>{row?.memo?.replace(/(.{80})..+/, "$1…")}</TableCell>
                                    {row?.amtC<0 ? (
                                            <TableCell align="left" sx={{ borderBottom: "none",color: '#eb5d0c', fontWeight: 600 }}>{(row?.amtC/100).toFixed(2)}</TableCell>   
                                          ):(
                                            <TableCell align="left" sx={{ borderBottom: "none",color: '#00B24A', fontWeight: 600 }}></TableCell>   
                                          )
                                        }
                                    <TableCell align="left" sx={{ borderBottom: "none", color: '#1A53FF', fontWeight: 600 }}>{(row?.balC/100)}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        colSpan={3}
                                        count={paymentsList?.total||0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        slotProps={{
                                            select: {
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                            },
                                        }}
                                        ActionsComponent={TablePaginationActions}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                    </div>
                </Paper>
                </div>
            </div>
             ):(
              <div className={classes.emptyContent}><span>You don't have any records</span></div>
            )}
        </div>
     
    )
}

export default PaymentsTable