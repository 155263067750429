import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import sendRegistraionRequest from './api/signup'
import validator from 'validator'
import { useAuth } from "./api/authStore"

const useStyles = makeStyles((theme) => ({
    box: {
        width: '512px',
        height: '608px',
        border: '1px',
        display: 'flex',
        alignItems: 'center',
        top: '240px',
        left: '740px',
        border: '1px solid grey',
        gap: '48px',
        paddingLeft: '96px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centered: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#8000FF',
      },
      boxInline: {
        width : '100%',
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '96px',
        paddingRight: '96px',
      },
      signIn: {
        width: '320px',
        height: '40px',
      },
      emailDiv:{
        paddingBottom: '50px',
      },
      emailInput: {
        width: '320px',
        height: '56px',
      },
      passwdInput: {
        width: '320px',
        height: '56px',
      },
      button: {
        width: '320px',
        height: '56px',
      },
      link: {
        color: '#1A53FF',
      },
      divSignInLink : {
        color: '#6E7680',
      },
      errorDiv : {
        color: '#E0004B',
        paddingBottom: '10px',
        fontSize: 12
      }
}))

const SignUp = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)
    const [isError, showError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [email, setEmail] =  useState(null)
    const [passwd, setPassword] =  useState("")
    const [repasswd, setRePassword] =  useState("")

    const [passwordHelperText, setPasswordHelperText] = React.useState("")
    const [rePasswordHelperText, setRePasswordHelperText] = React.useState("")
    const [emailHelperText, setEmailHelperText] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const [openEmail, setOpenEmail] = React.useState(false)
    const [openRePassError, setRePassError] = React.useState(false)
    const [buttonStatus, setButtonStatus] = React.useState(false)

    
    const handleChangeLogin = (e) => {
        setEmail(e.target.value)
    }

    const handleEmailValidate = (e) => {
        if(emailValidate(e.target.value)){
            setEmail(e.target.value)
            if (!passwordValidate(passwd)) {
                handleButtonStatus(true)
            } else if (!handleReValidatePassword(repasswd)) {
                handleButtonStatus(true)
            } else {
                handleButtonStatus(false)
            }
            setOpenEmail(false)
        }else {
            handleButtonStatus(true)
            setEmail(e.target.value)
            setOpenEmail(true)
        }
    }

    const handleChangePasswd = (e) => {
        setPassword(e.target.value)
    }

    const passwordValidate = (value) => {
  
        if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setPasswordHelperText("password should be at least 8 chars, 1 lower case, 1 upper case, 1 special chars, 1 number")
            return false
        } else {
            setPasswordHelperText("")
            return true
        }
    }

    const emailValidate = (email) => {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/g
       
        if(email && email.match(isValidEmail)){
            setEmailHelperText("")
            return true
        } else {
            setEmailHelperText("incorrect email format")
            return false
        }
    }
 
    const handleValidatePassword = (value) => {
        if (!passwordValidate(value) ){
            setOpen(true)
            handleButtonStatus(true)
            setPassword(value)
        }else {
            if(!emailValidate(email)) {
               handleButtonStatus(true)
            } else if(!handleReValidatePassword(repasswd)){
                handleButtonStatus(true)
            } else {
                handleButtonStatus(false)
            }
            setOpen(false)
            setPassword(value)
        }
    }

    const handleChangeRePasswd = (e) => {
        setRePassword(e.target.value)
    }

    const handleReValidatePassword = (repassword) => {
        setRePassword(repassword)

        if(passwd != repassword) {
            setRePasswordHelperText("Password and Re-password are not equal")
            setRePassError(true)
            handleButtonStatus(true)

            return false
        } else {
            setRePassError(false)
            setRePasswordHelperText("")
            handleButtonStatus(false)

            return true
        }
        
        
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleClickShowRePassword = () => setShowRePassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }


    const handleSingUpButton = async() => {
        try {
            const result = await sendRegistraionRequest(email, passwd)
            setToken(result)
            window.location.href = '/proxies'
        } catch (e) {
            if (e === 'userExist') {
                setErrorText("User already exist.")
            } else {
                setErrorText("Erorr occurs, try again please.")
            }
            showError(true)
        }
    }


    const handleButtonStatus = (status) => {
        setButtonStatus(status)
    }


    return (
        <div className={classes.centered}>
            <Box my={4} p={2} className={classes.box}>
                <div id="boxInline" className={classes.boxInline}>
                    <div className={classes.signIn}>Sign In</div>
                    <div id="elements">
                       <div className={classes.emailDiv}>
                            <TextField
                                error={openEmail}
                                id="outlined-size-small1"
                                placeholder="yours@example.com"
                                className={classes.emailInput}
                                InputProps={{
                                    style: {
                                      borderRadius: "16px",
                                    }
                                  }}
                                  onChange={handleChangeLogin}
                                  onBlur={handleEmailValidate}
                                  onMouseOver={handleEmailValidate}
                                  helperText={emailHelperText}
                            />
                        </div>
                        <div className={classes.emailDiv}>
                            <TextField
                                error={open}
                                id="outlined-size-small2"
                                placeholder="your password"
                                className={classes.passwdInput}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>,
                                }}
                                onChange={handleChangePasswd}
                                onBlur={(e)=>handleValidatePassword(e.target.value)}
                                helperText={passwordHelperText}
                            />
                        </div>
                        <div className={classes.emailDiv}>
                            <TextField
                                error={openRePassError}
                                id="outlined-size-small3"
                                placeholder="re password"
                                className={classes.passwdInput}
                                type={showRePassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowRePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showRePassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                                }}
                                onChange={handleChangeRePasswd}
                                onBlur={(e)=>handleReValidatePassword(e.target.value)}
                                helperText={rePasswordHelperText}
                            />
                        </div>
                        <div className={classes.errorDiv}>
                            {isError ? (
                                <span>{errorText}</span>
                                ): (
                                <span>&nbsp;</span> 
                                )
                            }
                        </div>
                            
                        <div className={classes.emailDiv}>
                                <Button disabled={buttonStatus} variant="contained" className={classes.button} sx={{ borderRadius: '8px' }} onClick={handleSingUpButton}>Sign Up</Button>
                        </div>
                        <div className={classes.divSignInLink}>
                            <span>Already have an account?</span>
                        </div>
                        <div className={classes.divSignInLink}>
                            <span><a href="/login">Sign In!</a></span>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
       
    )
}

export default SignUp