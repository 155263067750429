import React from 'react'
import ReactDOM from 'react-dom/client'
import { isIE } from 'react-device-detect'
import './index.css'

import AppWrapper from './AppWrapper'

const root = ReactDOM.createRoot(document.getElementById('root'))

let content = null
if (isIE) {
  content = <div>Not supported.</div>
} else {
  content = (
    <AppWrapper />
  )
}

root.render(
  <React.StrictMode>
   {content}
  </React.StrictMode>
)
