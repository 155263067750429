import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { jwtDecode } from "jwt-decode"
import { useAuth } from "../api/authStore"
import Tab from '@mui/material/Tab'
import PeopleIcon from '@mui/icons-material/People'
import SosIcon from '@mui/icons-material/Sos'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import BiotechIcon from '@mui/icons-material/Biotech'
import UserInfo from './UserInfo'
import ProxyInfo from './ProxyInfo'
import TicketsAdmin from './TicketsAdmin'

const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%',
        height: '100vh',
    },
    content: {
        alignItems: 'center',
        textAlign: 'center',
    }
}))

const Admin = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()
    const [isAdmin, setAdmin] = useState(false)

    const [value, setValue] = React.useState(1)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const decoded = jwtDecode(user.access_token)

    useEffect(()=>{
        decoded.permissions.map((item)=>{
            if(item.match(/admin/)){
                setAdmin(true)
            }
        })
    },[])

    // console.log(decoded)
    return (
        <div className={classes.main}>
            {isAdmin ? (
                <div className={classes.content}>
                    <TabContext value={value}>
                        <TabList value={value} onChange={handleChange}>
                            <Tab icon={<PeopleIcon color='primary'/>} label="USER INFO" value="1"/>
                            <Tab icon={<SosIcon color='primary'/>} label="TICKETS" value="2"/>
                            <Tab icon={<BiotechIcon color='primary'/>} label="PROXY INFO" value="3"/>
                        </TabList>
                        
                        <TabPanel value="1">
                            <UserInfo apiClient={props.apiClient} />
                        </TabPanel>

                        <TabPanel value="2">
                            <TicketsAdmin apiClient={props.apiClient}/>
                        </TabPanel>
                        <TabPanel value="3">
                            <ProxyInfo apiClient={props.apiClient} />
                        </TabPanel>
                    </TabContext>
                </div>
            ):(
                <></>
            )}
        </div>
    )
}

export default Admin