import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ReactCountryFlag from "react-country-flag"
import { useAuth } from "./api/authStore"
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { Avatar, TextField } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RenewProxyConfirmDialog from './RenewProxyConfirmDialog'
import RefundProxyConfirmDialog from './RefundProxyConfirmDialog'


const useStyles = makeStyles((theme) => ({
    linkStyle: {
        color: '#8000FF',
        textDecoration: 'none',
    },
    dotStyle: {
        color: '#6E7680'
    },
    ipLocationData: {
        height: '24px',
        fontWeight: '400',
        color: '#1A53FF',
        fontSize: '10px',
     },
     dialog: {
        border: '1px solid',
        borderRadius: '16px',
     },
     socksDiv: {
        display: 'flex'
     },
     socksColumnDiv: {
        flex: '50%',
     }
}))

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    variants: [
      {
        props: ({ expand }) => !expand,
        style: {
          transform: 'rotate(0deg)',
        },
      },
      {
        props: ({ expand }) => !!expand,
        style: {
          transform: 'rotate(180deg)',
        },
      },
    ],
  }))

const ProxyDetailsDialog = (props) => {
    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const classes = useStyles()

    const selectedProxy = props.selectedProxy
    const [expanded, setExpanded] = useState(false)
    const [saveWhitelistError, setSaveWhitelistError] = useState(false)
    const [whitelist, setWhitelist] = useState(null)
	const [buttonStatus, setButtonDisbled] = useState(true)
    const [openSuccessWhitelistUpdate, setOpenSuccessWhitelistUpdate] = useState(false)

    const [renewSuccess, setRenewSuccess] = useState(false)
    const [renewError, setRenewError] = useState(false)

    const [refundSuccess, setRefundSuccess] = useState(false)
    const [refundError, setRefundError] = useState(false)

    const [openConfirmRenew, setOpenConfirmRenew] = useState(false)
    const [openConfirmRefund, setOpenConfirmRefund] = useState(false)

    const handleClose = () => {
        props.setOpenDetails(false)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const handleSetSaveErrorClose = () => {
        setSaveWhitelistError(false)
    }

    const handleSetOpenSuccessWhitelistUpdate = () => {
        setOpenSuccessWhitelistUpdate(false)
    }

    const handleSetRenewErrorClose = () => {
        setRenewError(false)
    }

    const handleSetRenewSuccessClose = () => {
        setRenewSuccess(false)
    }

    const handleSetRefundSuccessClose = () => {
        setRefundSuccess(false)
    }

    const handleSetRefundErrorClose = () => {
        setRefundError(false)
    }

    const handleWhiteListChange = (e)  => {
		const regexExpIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi

		if (e.target.value == ""){
			setButtonDisbled(true)
			setWhitelist(e.target.value)
			return
		}

		const ipsArr = e.target.value.split(',')
		let checkedCount = 0

		ipsArr.forEach(element => {
			if(!element.match(regexExpIP)) {
				setButtonDisbled(true)
			} else  {
				checkedCount++
			}
		})

		if  (checkedCount == ipsArr.length) {
			setButtonDisbled(false)
		}

		setWhitelist(e.target.value)
	}

    const handleWhitelistSave = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).saveProxyWhitelist(selectedProxy.id, whitelist)

            setOpenSuccessWhitelistUpdate(true)
            props.setReloadActive(true)
        } catch (e) {
            console.error(e)
            setSaveWhitelistError(true)
        }
    }

    const handleRenewClick = async() => {
        try {
            setOpenConfirmRenew(true)
        } catch (e) {
            // console.error(e)
        }
    }

    const handleRefundClick = async() => {
        try {
            setOpenConfirmRefund(true)
        } catch (e) {
            // console.error(e)
        }
    }

    const renewProxy = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).renewProxy(selectedProxy.id)

            setRenewSuccess(true)
            props.setReloadActive(true)
            props.setOpenDetails(false)
        } catch (e) {
            console.error(e)
            setRenewError(true)
        }
    }

    const refundProxy = async(reason) => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).createRefundTicket(selectedProxy.id, reason)
            // console.log(reason)

            setOpenConfirmRefund(false)
            setRefundSuccess(true)
            props.setOpenDetails(false)
        } catch (e) {
            console.error(e)
            setRefundError(true)
        }
    }


    // console.log(selectedProxy)
    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                PaperProps={{ sx: { borderRadius: "10px" , borderColor:'#3b82f680', border: '1px solid', maxWidth: '420px',  width: "100%" } }}
                >
                <Card>
                    <CardHeader
                        title={"Proxy ID : "+selectedProxy?.id}
                        titleTypographyProps={{sx:{fontSize: 16, color:'#6E7680'}}}
                        avatar={
                            <Avatar sx={{backgroundColor: '#11ffee00'}}>
                                <ReactCountryFlag countryCode={selectedProxy?.info?.loc?.cc} svg style={{width: '30px', height: '24px'}}/>
                            </Avatar>
                        }
                        subheader={
                            <Typography sx={{fontSize: 12, color:'#6E7680'}}><span>{selectedProxy?.info?.loc?.reg}</span><span className={classes.dotStyle}> • </span><span>{selectedProxy?.info?.loc?.city}</span><span className={classes.dotStyle}> • </span><span className={classes.dotStyle}>{selectedProxy?.info?.loc?.zip}</span><span className={classes.dotStyle}> • </span><span>{selectedProxy?.info?.loc?.isp}</span></Typography>
                        }
                        
                    />
                    
                    <CardContent sx={{paddingBottom: '0px'}}>
                        <Typography sx={{fontSize: 14, color:'#6E7680', paddingBottom: '20px'}}>
                            Authorisation by username :
                        </Typography>
                        <div className={classes.socksDiv}>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 12}}>
                                    Socks5 server:port : 
                                </Typography>
                            </div>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 14}}>
                                    {selectedProxy?.socks?.ip} : {selectedProxy?.socks?.port}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.socksDiv}>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 12}}>
                                    Socks5 username:password : 
                                </Typography>
                            </div>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 14}}>
                                    {selectedProxy?.socks?.user} : {selectedProxy?.socks?.pwd}
                                </Typography>
                            </div>
                        </div>
                        <Typography sx={{fontSize: 14, color:'#6E7680', paddingTop: '15px'}}>
                            Authorisation by ip :
                        </Typography>
                        <div className={classes.socksDiv}>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 12}}>
                                    Socks5 server:port : 
                                </Typography>
                            </div>
                            <div className={classes.socksColumnDiv}>
                                <Typography sx={{fontSize: 14}}>
                                    {selectedProxy?.authPort?.node} : {selectedProxy?.authPort?.port}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.socksDiv} style={{paddingTop: '20px'}}>
                            <div className={classes.socksColumnDiv}>
                                <TextField size="small" 
                                        //    defaultValue={selectedProxy?.authPort?.whitelist} 
                                           sx={{padding: '0px 0px', paddingTop:'0'}} 
                                           inputProps={{sx:{padding:'5px 5px', width: '140px'}}} 
                                           onChange={handleWhiteListChange}
                                           value={whitelist||selectedProxy?.authPort?.whitelist}
                                />           
                            </div>
                            <div className={classes.socksColumnDiv}>
                                <Button disabled={buttonStatus}
                                        variant="outlined" 
                                        style={{width: '20px', height: '32px'}} 
                                        onClick={handleWhitelistSave}>save</Button>
                            </div>
                        </div>
                        
                    </CardContent>
                    <CardActions disableSpacing sx={{paddingTop: '0px'}}> 
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                            <ExpandMoreIcon />
                            </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <div className={classes.socksDiv} style={{paddingTop: '43px'}}>
                            <div className={classes.socksColumnDiv}>
                            <Button variant="contained" style={{width: '160px', height: '32px'}} onClick={handleRefundClick}>refund</Button>
                            </div>
                            <div className={classes.socksColumnDiv}>
                                <Button variant="contained" style={{width: '160px', height: '32px'}} onClick={handleRenewClick}>renew</Button>
                            </div>
                        </div>
                    </CardContent>
                    </Collapse>
                </Card>
            </Dialog>
            <RenewProxyConfirmDialog openConfirmRenew={openConfirmRenew} 
                                     setOpenConfirmRenew={setOpenConfirmRenew}
                                     renewProxy={renewProxy}
                                     selectedProxy={selectedProxy}/>

            <RefundProxyConfirmDialog openConfirmRefund={openConfirmRefund}
                                      refundProxy={refundProxy}
                                      setOpenConfirmRefund={setOpenConfirmRefund}
                                      selectedProxy={selectedProxy}/>

            <Snackbar
              open={saveWhitelistError}
              autoHideDuration={6000}
              onClose={handleSetSaveErrorClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Error saving whitelist.</Alert>
            </Snackbar>
            <Snackbar
              open={openSuccessWhitelistUpdate}
              autoHideDuration={6000}
              onClose={handleSetOpenSuccessWhitelistUpdate}
              severity="success"
              variant="filled"
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Proxy whitelist updated!</Alert>
            </Snackbar>
            <Snackbar
              open={renewError}
              autoHideDuration={6000}
              onClose={handleSetRenewErrorClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Error renew proxy.</Alert>
            </Snackbar>
            <Snackbar
              open={renewSuccess}
              autoHideDuration={6000}
              onClose={handleSetRenewSuccessClose}
              severity="success"
              variant="filled"
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Proxy renewed successfully!</Alert>
            </Snackbar>
            <Snackbar
              open={refundError}
              autoHideDuration={6000}
              onClose={handleSetRefundErrorClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Error create refund ticket.</Alert>
            </Snackbar>
            <Snackbar
              open={refundSuccess}
              autoHideDuration={6000}
              onClose={handleSetRefundSuccessClose}
              severity="success"
              variant="filled"
              anchorOrigin={{vertical: 'top', horizontal: 'center' }} >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >Refund ticket was created!</Alert>
            </Snackbar>
        </>
    )
}

export default ProxyDetailsDialog