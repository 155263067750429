import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import LeftSideMenu from './LeftSideMenu'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import sendRegistraionRequest from './api/signup'
import validator from 'validator'
import { useAuth } from "./api/authStore"

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        height: '100%',
    },
    main2: {
        width: '75%',
        height: '100vh',
    },
    header: {
        // height: '72px',
        margin: '32px',
        fontWeight: 600,
    },
    content: {
        height: '72px',
        marginLeft: '32px',
        // marginRight: '32px',
    },
    boxInline: {
        width : '100%',
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '96px',
        paddingRight: '96px',
      },
      signIn: {
        width: '320px',
        height: '40px',
      },
      emailDiv:{
        paddingBottom: '50px',
      },
      emailInput: {
        width: '320px',
        height: '56px',
      },
      passwdInput: {
        width: '320px',
        height: '56px',
      },
      button: {
        width: '320px',
        height: '56px',
      },
      link: {
        color: '#1A53FF',
      },
      divSignInLink : {
        color: '#6E7680',
      },
      errorDiv : {
        color: '#E0004B',
        paddingBottom: '10px',
        fontSize: 12
      }
}))

const ChangePassword = (props) => {
    const classes = useStyles()

    const { user, getAccessTokenSilently, setToken, logout } = useAuth()

    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setShowRePassword] = useState(false)

    const [isError, showError] = useState(false)
    const [errorText, setErrorText] = useState("")

    const [passwordHelperText, setPasswordHelperText] = React.useState("")
    const [rePasswordHelperText, setRePasswordHelperText] = React.useState("")
    const [openRePassError, setRePassError] = React.useState(false)
    const [buttonDisable, setButtonStatus] = React.useState(true)

    const [open, setOpen] = React.useState(false)
   
    const [oldpasswd, setOldPassword] =  useState("")
    const [passwd, setPassword] =  useState("")
    const [repasswd, setRePassword] =  useState("")


    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleClickShowRePassword = () => setShowRePassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleChangePasswd = (e) => {
        setPassword(e.target.value)
    }
    
    const handleChangeOldPasswd = (e) => {
        setOldPassword(e.target.value)
    }

    const passwordValidate = (value) => {
  
        if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setPasswordHelperText("password should be at least 8 chars, 1 lower case, 1 upper case, 1 special chars, 1 number")
            return false
        } else {
            setPasswordHelperText("")
            return true
        }
    }

    const handleValidatePassword = (value) => {
        if (!passwordValidate(value) ){
            setOpen(true)
            setButtonStatus(true)
            setPassword(value)
        } else if(!validateRePassword(repasswd)){
            setRePassError(true)
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
            setOpen(false)
            setRePassError(false)
            setPassword(value)
        }
    }

    const handleChangeRePasswd = (e) => {
        setRePassword(e.target.value)
    }

    const validateRePassword = (repassword) => {
        setRePassword(repassword)

        if(passwd != repassword) {
            // console.log(passwd +' : '+repassword)
            setRePasswordHelperText("Password and Re-password are not equal")
            setRePassError(true)
            setButtonStatus(true)
            
            return false
        } else {
            // console.log(passwd +' : '+repassword)
            setRePassError(false)
            setRePasswordHelperText("")
            setButtonStatus(false)
            
            return true
        }
        
        
    }

    const hadleButtonClick = async() => {
        try {
            const response = await props.apiClient.setToken(getAccessTokenSilently()).changeUserPassword(oldpasswd, passwd)
            console.log(response)
        } catch (e) {
            console.log(e)
            showError(true)
            setErrorText("Erorr occurs, try again please.")
        }
    }

    return (
        <div className={classes.main}>
            <LeftSideMenu active={props.active} user={props.user}/>
            <div className={classes.main2}>
                <div className={classes.header}>Account:Change Password</div>
                <div className={classes.content}><span>{props.text}</span></div>
                <div id="boxInline" className={classes.boxInline}>
                    <div id="elements">
                       <div className={classes.emailDiv}>
                            <TextField
                                id="outlined-size-small1"
                                placeholder="your current password"
                                className={classes.passwdInput}
                                type={showOldPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>,
                                }}
                                onChange={handleChangeOldPasswd}
                            />
                        </div>
                        <div className={classes.emailDiv}>
                            <TextField
                                error={open}
                                id="outlined-size-small2"
                                placeholder="your new password"
                                className={classes.passwdInput}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>,
                                }}
                                onChange={handleChangePasswd}
                                onBlur={(e)=>handleValidatePassword(e.target.value)}
                                helperText={passwordHelperText}
                            />
                        </div>
                        <div className={classes.emailDiv}>
                            <TextField
                                error={openRePassError}
                                id="outlined-size-small3"
                                placeholder="re password"
                                className={classes.passwdInput}
                                type={showRePassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {
                                        borderRadius: "8px",
                                    },
                                    endAdornment: <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowRePassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                {showRePassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>,
                                }}
                                onChange={handleChangeRePasswd}
                                onBlur={(e)=>validateRePassword(e.target.value)}
                                helperText={rePasswordHelperText}
                            />
                        </div>
                        <div className={classes.errorDiv}>
                            {isError ? (
                                <span>{errorText}</span>
                                ): (
                                <span>&nbsp;</span> 
                                )
                            }
                        </div>
                            
                        <div className={classes.emailDiv}>
                                <Button disabled={buttonDisable} variant="contained" className={classes.button} sx={{ borderRadius: '8px' }} onClick={hadleButtonClick}>Change</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword