import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Login from './Login'
import Shop from './Shop'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import NoPage from './NoPage'
import ActiveProxies from './ActiveProxies'
import History from './History'
import Payments from './Payments'
import Tickets from './Tickets'
import ChangePassword from './ChangePassword'
import Admin from './admin/Admin'

function App(props) {
  return (
    <BrowserRouter>
     <Routes>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="passwdrecovery" element={<ForgotPassword />} />
        <Route path="admin" element={<Admin apiClient={props.apiClient} />} />
        <Route path="account" element={<ChangePassword user={props.userData} active={props.active} apiClient={props.apiClient}/>} />
        <Route path="proxies" element={<Shop user={props.userData} active={props.active} apiClient={props.apiClient} />}/>
        <Route path="active" element={<ActiveProxies user={props.userData} active={props.active} apiClient={props.apiClient} setReloadActive={props.setReloadActive}/>}/>
        <Route path="history" element={<History user={props.userData} active={props.active} apiClient={props.apiClient}/>}/>
        <Route path="transactions" element={<Payments user={props.userData} active={props.active} apiClient={props.apiClient}/>}/>
        <Route path="tickets" element={<Tickets user={props.userData} active={props.active} apiClient={props.apiClient}/>}/>
        <Route path="*" element={<NoPage />} />
    </Routes> 
    </BrowserRouter>
  );
}

export default App;
