import {signupUrl} from '../config/config'

const sendRegistraionRequest = async (email, passwd) => {
    try {
        const url = signupUrl
        
        const body = {
            username: email,
            password: passwd,
        }

		const options = {
    		method: 'POST',
    		mode: 'cors',
    		headers: {
    		    'Accept': 'application/json',
				'Content-Type': 'application/json'
    		  },
			body: JSON.stringify(body)
        }
    	
		const resp = await fetch(url, options).then(response => {
        	return response.json()
	    }).then(responseJson => {
	    	// if (responseJson.result == true) {
	    		// return responseJson
	    	// } else {
	    		if (responseJson.hasOwnProperty('error')) {
	    			throw responseJson.error
	    		} else {
					return responseJson
				}
        }).catch(error => {
			// console.log(error)
        	throw error
        })
		// console.log('RESP')
        // console.log(resp)
        
    	return resp
    } catch (err) {
    	console.error('ERROR SIGNUP:'+err)
        
        throw err
    }
};

export default sendRegistraionRequest